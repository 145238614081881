import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import Cancel from '../svg/Cancel';
import ExitIcon from '../svg/ExitIcon';
import Users from '../svg/Users';
import UsersIcon from '../svg/UsersIcon';

const ItemExit = ({index,obj,onClose }) => {

      const { ref, focused, focusKey,focusSelf } = useFocusable({
            focusKey: "S" + obj.id,
            onEnterPress: () => {
                  if (typeof onClose === 'function') {
                        onClose(obj.id);
                  }else{
                        console.log(typeof onClose)
                  }
            },
      });


      return (
            <FocusContext.Provider value={focusKey}>
            <div ref={ref} onMouseEnter={focusSelf}
                 onClick={()=>onClose(obj.id)}
                  className="group relative focusable w-full transition-all duration-300 h-28 outline-none border-0"
            >
                  <div
                        className={(focused ?"bg-white":"")+" w-1/3 mx-auto h-[0.4rem] bottom-[0.6rem] absolute left-1/2 -translate-x-1/2 "}
                  />

                  <div className="backdrop-blur-3xl h-24 bg-categoryColor relative w-full">
                        <div className="w-full h-full bg-center bg-cover absolute bottom-0 left-0 flex flex-col justify-center text-center">
                                    <div className='flex justify-center items-center'>
                                          {obj.id == '0' && <ExitIcon />
}
                                          {obj.id == '1' && <UsersIcon />
}
                                          {obj.id == '2' && <Cancel classes="fill-textcolor2" />
}
                                          <div className="text-white text-normal my-2 mx-4 text-2xl">{obj.title}</div>
                                    </div>                              <div
                                    className={(focused ? "block" : "hidden") + " absolute left-0 top-0 right-0 w-full h-full  "}
                                    style={{ backgroundImage: `url('../images/bordergradient.svg')` }}
                              />
                        </div>
                  </div>
            </div>
            </FocusContext.Provider>
      );
};

export default ItemExit;
