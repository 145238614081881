import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { motion } from 'framer-motion';

const ItemSettingSheetSize = ({ index, obj, onClose, focusKey: focusKeyParam }) => {

      const { ref, focused, focusKey,focusSelf } = useFocusable({
            focusKey: focusKeyParam,
            onEnterPress: () => {
                  if (typeof onClose === 'function') {
                        onClose(obj);
                  }else{
                        console.log(typeof onClose)
                  }
            },
      });


      return (
            <FocusContext.Provider value={focusKey}>
            <div ref={ref}
                 onClick={() => onClose(obj)}
                 onMouseEnter={focusSelf}  className="group relative focusable w-full transition-all duration-300 h-[6rem] outline-none border-0 max-w-[10rem] m-2 "
            >


                  <div className={(focused ? "bg-secondary2" : "bg-categoryColor") + " h-[5rem]  relative w-full rounded-xl"}>
                              <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: focused ? 1 : 0 }}
                                    transition={{ duration: 0.3 }}
                                    className={"absolute h-full  w-full flex justify-center items-end"} >

                                    <div className={(focused ? "bg-white " : "") + "w-1/3 absolute -bottom-[4px] h-[5px]  mx-auto  left-1/2 transform -translate-x-1/2 "} />
                                    <div className="w-full h-full overflow-hidden flex justify-center items-end">
                                          <div className="bg-white w-1/3   transform h-8 filter  top-0 overflow-hidden
                              blur-[2rem]
" ></div>
                                    </div>
                              </motion.div>
                        <div className="w-full h-full bg-center bg-cover absolute bottom-0 left-0 flex flex-col justify-center text-center">
                              <h2 className="text-white text-normal my-2 text-xl">{obj.title}</h2>
                              <div
                                    className={(focused ? "block" : "hidden") + " absolute left-0 top-0 right-0 w-full h-full  "}
                                    style={{ backgroundImage: `url('../images/bordergradient.svg')` }}
                              />
                        </div>
                  </div>
            </div>
            </FocusContext.Provider>
      );
};

export default ItemSettingSheetSize;
