import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useNavigate } from "react-router-dom";
import { useCustomEvent } from "../../Event/CustomEventContext";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";

function ItemSetting({ obj, position, speed, rerender }) {
      const navigate = useNavigate();
      const { emitEvent } = useCustomEvent();
      const [settingParams, setSettingParams] = useState<string>("off");

      const { ref, focused,focusSelf} = useFocusable({

            onEnterPress: () => {
                  emitEvent('focusEvent', obj);

            },
      });
      useEffect(() => {
            if (position==5) {
                  setSettingParams(speed)
            }

      }, [speed]);
      function isElementInViewport(el) {
            var rect = el.getBoundingClientRect();

            return (
                  rect.top >= 0 &&
                  rect.left >= 0 &&
                  rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                  rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
      }
      useEffect(() => {
            if (focused) {

                  if (!isElementInViewport(ref.current)) {
                        ref.current.scrollIntoView({
                              behavior: 'instant',
                              block: 'center'
                        });
                  }
            }
      }, [focused]);
      useEffect(() => {
            if (position == 0) {
                  let language = localStorage.getItem('language');
                  if (!language) {
                        language = "English";
                  }
                  setSettingParams(language)

            }
            if (position == 1) {
                  let language = localStorage.getItem('password');
                  if (!language) {
                        language = "off";
                  }else{
                        setSettingParams('On')

                  }

            }
            if (position == 2) {
                  let streamFormat = localStorage.getItem('streamFormat');
                  if (!streamFormat) {
                        streamFormat = "m3u8";
                  }
                  setSettingParams(streamFormat)

            }
            if (position == 3) {
                  let streamFormat = localStorage.getItem('player');
                  if (!streamFormat) {
                        streamFormat = "Avplayer";

                  }
                  if (streamFormat == 'Avplayer' && !window.Tizen) { streamFormat = "videojs" }

                  setSettingParams(streamFormat)

            }
            if (position == 4) {
                  let streamFormat = localStorage.getItem('timeformat');
                  if (!streamFormat) {
                        streamFormat = "24 hours";
                  }
                  setSettingParams(streamFormat)

            }
            if (position == 6) {
                  let streamFormat = localStorage.getItem('listType');
                  if (!streamFormat) {
                        streamFormat = "List";
                  }
                  setSettingParams(streamFormat)

            }
      }, [rerender]);


      return <div className="p-2 w-1/3"  onMouseEnter={focusSelf} onClick={()=>emitEvent('focusEvent', obj)}><div ref={ref} className="  outline-none text-left	h-full" > <div
            className=" relative focusable w-full transition-all duration-300 h-52 outline-none border-0 cursor-pointer"
      >
            <div className={(focused ? "bg-white " : "") + "w-1/3 absolute -top-[4px] h-[5px]  mx-auto left-1/2 transform -translate-x-1/2 "} />
            <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: focused ? 1 : 0 }}
                  transition={{ duration: 0.3 }}
                  className={"relative h-full overflow-hidden w-full flex justify-center"} >


                  <div className="bg-white w-1/3   transform h-20 filter  top-0 overflow-hidden blur-[3rem]
" ></div>
            </motion.div>
            <div className=" absolute h-full  bg-categoryColor left-0 right-0 top-[0.3rem] top-0 bottom-0  w-full">
                  <div
                        className="w-full h-full bg-center bg-cover absolute bottom-0 left-0 flex flex-col justify-end p-8"
                  >
                        <img src={"/images/setting"+position+".svg"} className="w-12" />
                        <h2 className="text-white text-2xl my-2 mt-4">{obj.title}</h2>
                        <span className="text-textcolor2 text-lg">{settingParams}</span>
                  </div>
            </div>

            <div
                  className={(focused ? "block " : "hidden") + " absolute left-0 top-0 right-0 w-full  bottom-0 "}
                  style={{"backgroundImage": "url('/images/bordergradient.svg')"}}
            />
      </div>
      </div>
      </div>
}

export default ItemSetting;