import { LegacyRef, memo, useEffect, useMemo, useRef, useState } from "react";
import { useGlobalContext } from "../model/GlobalContext";
import { FocusContext, pause, resume, setFocus, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import 'react-virtualized/styles.css'; // import the styles
import './Home.css'; // import the styles
import React from "react";
import ReactPlayer from 'react-player';
import { deleterow, generateurlplayer, getVideoPositions, insertData, insertDatarecent, storeVideoPositions } from "../model/constant";
import CircleButton from "./sub/CircleButton";
import ReloadIcon from "./svg/ReloadIcon";
import ResizeIcon from "./svg/ResizeIcon";
import Setting from "./svg/Setting";
import useEventListener from "@use-it/event-listener";
import Progress from "./sub/Progress";
import CircleButtonPlayer from "./sub/CircleButtonPlayer";
import RWIcon from "./svg/RWIcon ";
import FFICON from "./svg/FFICON";
import CircleButtonPlay from "./sub/CircleButtonPlay";
import { useCustomEvent } from "../Event/CustomEventContext";
import BottomSheet from "./Settings/BottomSheet";
import Size from "./Settings/size";
import SettingPlayer from "./Settings/SettingPlayer";
import { useNavigate } from "react-router-dom";
import LikeEmpty from "./svg/LikeEmpty";
import CircleButtonLike from "./sub/CircleButtonLike";
import Like from "./svg/Like";
import NoInternet from "./Settings/NoInternet";

const ESCAPE_KEYS = ['27', '10009'];
const REWIND_KEYS = ['412'];
const FF_KEYS = ['417'];
const ENTER_KEY = ['13']
const PAUSE_KEYS = ['19'];
const PLAY_KEYS = ['415'];
const PAUSEPLAY_KEYS = ['10252', "99"];
const STOP_KEYS = ['413'];
interface VideoPlayerProps {
      videoSource: string;
      handleDuration: any;
      handleProgress: any;
      currentVideoSize: any
      playerRef: LegacyRef<ReactPlayer>
      playing:boolean
      changeplaying:any,
      rate : number
      brightness:number;
      handleBuffer: any;
      handleBufferEnd: any;
      handleError: any;
}

// VideoPlayer component
const VideoPlayer: React.FC<VideoPlayerProps> = memo(({ videoSource, handleDuration, handleProgress, currentVideoSize, playerRef, playing, changeplaying, rate, brightness,handleBuffer,handleBufferEnd,handleError } ) => {
      // Your video player logic here




      return (
            <>           <div style={{ position: 'relative', width: '100%', paddingTop: `${(1 / (currentVideoSize.size == "4:3" ? 4 / 3 : 16 / 9)) * 100}%`, overflow: 'hidden' }}>
                  <ReactPlayer
                        url={videoSource} // Replace with your actual video source
                        width="100%"
                        height="100%"
                        ref={playerRef}
                        style={{ position: 'absolute', top: 0, left: 0 }}
                        playbackRate={rate}
                        onDuration={handleDuration} // Callback to handle the duration
                        onProgress={handleProgress} // Callback to handle the progress
                        playing={playing}
                        onEnded={changeplaying} // Update playing state when the video ends
                        onBuffer={handleBuffer} // Buffering begins
                        onBufferEnd={handleBufferEnd} // Buffering ends
                        onError={handleError} // Handle video player errors
                        className={currentVideoSize.class}

                  />
            </div>

            </>

      );
});
const MoviePlayer: React.FC = () => {
      let timeFormat = localStorage.getItem("timeformat") || "12hours"

      let opsiJam: Intl.DateTimeFormatOptions = {
            hour: "numeric",
            minute: "numeric",
            hour12: timeFormat == "12hours" ? true : false,
      };
      let opsiTanggal: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "long",
            day: "numeric",
      };
      const timeoutIdRef = useRef<any | null>(null);
      const { addEventListener, removeEventListener } = useCustomEvent();
      const [showUI, setShowUI] = useState("0")
      const [time, setTime] = useState<any>({})

      const [isOpen, setIsOpen] = useState(false);
      const [timeOut, setTimeoutRef] = useState<any>();
      const [timeOutTimeUpdate, changetimeOutTimeUpdate] = useState<any>(null);
      const [subtitile, setSubtitile] = useState<string>("");
      const [trucks, setTrucks] = useState<any[]>([]);
      const [hasText, sethasText] = useState(false);

      const [hasAudio, sethasAudio] = useState(false);

      const [isBottomSheetOpen, setBottomSheetOpen] = useState(false);
      const [currentVideoSize, setCurrentVideoSize] = useState({ id: "0", class: "contain", size: "16:9", title: "Default" });

      const aspectRatio = 4 / 3; // Replace with your desired aspect ratio
      const aspectRatio2 = 16 / 9; // Replace with your desired aspect ratio
      let playerType = localStorage.getItem('player') || (window.Tizen ? 'Avplayer' : 'videojs');
      if (playerType == 'Avplayer' && !window.Tizen) { playerType = "videojs" }

      const handleBuffer = () => {
            setBuffering(true);
      };

      const handleBufferEnd = () => {
            setBuffering(false);
      };

      const handleError = (err) => {
            setError(err);
            setBuffering(false)

            console.error('Video player error:', err);
      };
      useEffect(() => {


            const resize = () => {
                  setBottomSheetOpen(true)
            }
            const setting = () => {
                  toggleDropdown()
            }


            addEventListener('play', handlePausePlay);
            addEventListener('rewind', rewindVideo);
            addEventListener('forward', forwardVideo);
            addEventListener('resize', resize);
            addEventListener('setting', setting);

            addEventListener('like', handleLike);

            return () => {
                  removeEventListener('play', handlePausePlay);
                  removeEventListener('setting', setting);
                  removeEventListener('rewind', rewindVideo);
                  removeEventListener('forward', forwardVideo);
                  removeEventListener('resize', resize);
                  removeEventListener('like', handleLike);

            };
      }, [addEventListener, removeEventListener]);

      const [srcVod, setSrcVod] = useState<string>("");
      const [duration, setDuration] = useState(0);
      const [currentTime, setCurrentTime] = useState(0);
      const memoizedCurrentTime = useMemo(() => currentTime, [currentTime]);

      const [ffrewind, setFFRewind] = useState(0);
      const [playing, setPlaying] = useState(true);
      const [error, setError] = useState(null);
      const [errorInternet, setErrorInternet] = useState<boolean>(false);

      const [playedSeconds, setPlayedSeconds] = useState(0);
      const rewindNotificationValueRef = useRef<HTMLDivElement | null>(null);
      const forwardNotificationValueRef = useRef<HTMLDivElement | null>(null);
      const notificationsRef = useRef([]);
      const [rate, setRate] = useState<number>(1);
      const navigate = useNavigate();
      const [movieLiked, setMovieLiked] = useState<boolean>(false);
      const [isAvplayerInit, setAvplayerInit] = useState<boolean>(false);

      const [rewindSpeed, setRewindSpeed] = useState<number>(-10);
      const [forwardSpeed, setForwardSpeed] = useState<number>(10);

      const playerRef = useRef<ReactPlayer | null>(null);
      const [buffering, setBuffering] = useState(true);

      const { movie, profile, changeListVodFav, listVodFav, changeListVodRecent, listVodRecent } = useGlobalContext();
      const handleDuration = (duration) => {
            // Set the duration in state
            setDuration(duration);
            if(movie.playon){

                  if (playerType !== 'Avplayer') {
                        console.log("movie.playon,movie.playon", movie.playon)
                        if (playerRef.current) {
                              setError(null)

                              setCurrentTime(movie.playon)

                              playerRef.current.seekTo(movie.playon, 'seconds');
                        }
                  } else {
                        setCurrentTime(movie.playon)
                        window.Tizen.Player.seekTo(movie.playon * 1000, 'seconds');


                  }
            }

      };
      useEffect(() => {
            setMovieLiked(listVodFav.includes(String(movie.stream_id)))
      }, [listVodFav]);


      useEffect(() => {

                        const videoPositions = getVideoPositions();
                        videoPositions[String(movie.stream_id+"")] = currentTime;
            setError(null)

                        storeVideoPositions(videoPositions);
                        console.log(videoPositions)



      }, [currentTime]);

      const { ref, focusSelf, focusKey } = useFocusable({ focusKey:'moviePlayer',trackChildren: true });
      useEffect(() => {
            setFocus("focusPlay")
      }, [focusSelf]);
      useEffect(() => {
            setTimeout(() => {

            setSrcVod(generateurlplayer("movie", movie.stream_id, movie.container_extension, profile))
            handleShowDiv()
                  const arrayFav = [...listVodRecent]
                  arrayFav.push(movie.stream_id)
                  changeListVodRecent(arrayFav)
                  insertDatarecent(movie.stream_id + "", "movie", profile)

      })
      }, [movie]);
      useEffect(() => {
            if (playerType == "Avplayer") {
                  if (!isAvplayerInit) {
                        var playerConfig = {
                              url: srcVod,
                              islive: false
                        }
                        window.Tizen.Player.create(playerConfig);
                        setAvplayerInit(true)
                  } else {
                        window.Tizen.Player.changeVideo(srcVod);
                  }
            }
      }, [srcVod]);

      const formatTime = (timeInSeconds) => {
            const hours = Math.floor(timeInSeconds / 3600);
            const minutes = Math.floor((timeInSeconds % 3600) / 60);
            const seconds = Math.floor(timeInSeconds % 60);

            const formattedTime = `${String(hours).padStart(2, '0') }:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
            return formattedTime;
      };
      const handleProgress = ({ playedSeconds }) => {
            // Set the current playback time in state
            setCurrentTime(playedSeconds);
      };

      const handlePausePlay = () => {

                  setPlaying((playing) => !playing);

      };

      const handleStop = () => {

            setPlaying(false);

      };

      const updateCurrentTime = (delta: number) => {
            const isRewinding = delta < 0;
            console.log('isRewinding', delta)

            if (isRewinding) {
                  setRewindSpeed((prevSpeed) => prevSpeed < 60 ? (prevSpeed + delta) :prevSpeed+60);
                  setForwardSpeed(10);
            } else {
                  setForwardSpeed((prevSpeed) => prevSpeed < 60 ? (prevSpeed + delta) : prevSpeed+60);
                  setRewindSpeed(-10);
            }

            if (timeOut) {
                  clearTimeout(timeOut);
                  console.log('clearrrrrrrrrrrrrr')
            }

            const speed = isRewinding ? rewindSpeed : forwardSpeed;

            console.log('speed', rewindSpeed)




            const NotificationValue = isRewinding ? rewindNotificationValueRef.current : forwardNotificationValueRef.current;
            if (NotificationValue) {
                  setFFRewind(Math.abs(speed))
            }
            var tt = setTimeout(() => {

                  if (playerType !== 'Avplayer') {
                        if (playerRef.current) {

                              const newTime = Math.min(playerRef.current.getCurrentTime() + speed, playerRef.current.getDuration()); // Fast forward 10 seconds
                              setCurrentTime(newTime)

                              playerRef.current.seekTo(newTime, 'seconds');
                        }
                  } else {
                        const newTime = Math.min(memoizedCurrentTime + speed, duration); // Fast forward 10 seconds
                        setCurrentTime(newTime)
                        console.log((newTime))
                        window.Tizen.Player.seekTo(newTime * 1000);


                  }
                  setRewindSpeed(-10);
                  setForwardSpeed(10);
            }, 1000)
            setTimeoutRef(tt)



      };

      const animateNotificationIn = (isRewinding: boolean) => {
            const notificationRef = isRewinding ? rewindNotificationValueRef : forwardNotificationValueRef;
            if (notificationRef.current) {
                  notificationRef.current.classList.add('animate-in');
            }
      };

      const forwardVideo = () => {
            updateCurrentTime(10);
            animateNotificationIn(false);
      };

      const rewindVideo = () => {
            updateCurrentTime(-10);
            animateNotificationIn(true);
      };

      useEffect(() => {
            if (playerType == 'Avplayer') {

            if(playing){
                  window.Tizen.Player.play()
            }else{
                  window.Tizen.Player.pause()

            }
      }
      }, [playing]);
      useEffect(() => {



            const animateNotificationOut = (notificationRef: React.RefObject<HTMLDivElement>) => {
                  if (notificationRef.current) {
                        notificationRef.current.classList.remove('animate-in');
                  }
            };

            // Event Listeners
            //   videoRef.current.addEventListener('click', togglePlay);
            //  videoRef.current.addEventListener('dblclick', doubleClickHandler);
            if (rewindNotificationValueRef.current) {
                  rewindNotificationValueRef.current.addEventListener('animationend', () => animateNotificationOut(rewindNotificationValueRef));
            }

            if (forwardNotificationValueRef.current) {
                  forwardNotificationValueRef.current.addEventListener('animationend', () => animateNotificationOut(forwardNotificationValueRef));
            }



            handleShowDiv()
            document.body.classList.remove('bg-primary');
            const playerEvent = (event) => {
                  const playerObj = event.detail;
                  if (playerObj.type === "playerready") {
                        if (movie.playon) {

                              if (playerType !== 'Avplayer') {
                                    console.log("movie.playon,movie.playon", movie.playon)
                                    if (playerRef.current) {

                                          setCurrentTime(movie.playon)

                                          playerRef.current.seekTo(movie.playon, 'seconds');
                                    }
                              } else {
                                    setCurrentTime(movie.playon)
                                    window.Tizen.Player.seekTo(movie.playon * 1000, 'seconds');


                              }
                        }
                        setTimeout(() => {
                              let track = window.Tizen.Player.getTracks()
                              if (track.tracks) {
                                    setTrucks(track.tracks)
                                    sethasAudio(track.tracks.filter((au) => { return au.type == 'AUDIO' }).length > 1)
                                    sethasText(track.tracks.filter((au) => { return au.type == 'TEXT' }).length > 1)
                              }
                        }, 500)
                  }
                  if (playerObj.type === "duration") {

                        setDuration(playerObj.text)
                        setError(null)

                                          }
                  if (playerObj.type === "subtitle") {
                        setSubtitile(playerObj.text)
                  }
                  if (playerObj.type === "changeImg") {


                  }

                  if (playerObj.type === "time") {
                        setCurrentTime(playerObj.text)
                  }

                        if (playerObj.type === "progress") {
                        if (playerObj.show) {
                              setBuffering(true)
                              setError(null)



                        } else {
                              setBuffering(false)
                              setError(null)

                        }
                  }
                  if (playerObj.type === "error") {
                        if (playerObj.show) {

                              setBuffering(false)
                              setError(event)



                        } else {
                              setBuffering(false)
                              setError(null)

                        }
                  }
                  if (playerObj.type === "nointernet") {
                        if (playerObj.show) {

                              setErrorInternet(true)
                              pause()
                        }
                        else {
                              setErrorInternet(false)
                              resume()
                              focusSelf()
                        }

                  }
            }
            window.document.addEventListener('playerEvent', playerEvent);
            let interval2 = setInterval(() => {

                  let date = new Date();
                  let tanggal = new Intl.DateTimeFormat("en-US", opsiTanggal).format(date);
                  let jam = new Intl.DateTimeFormat("en", opsiJam).format(date);

                  setTime({ "time": jam, "date": tanggal })
            }, 1000)
            return () => {
                  if (rewindNotificationValueRef.current) {
                        rewindNotificationValueRef.current.removeEventListener('animationend', () => animateNotificationOut(rewindNotificationValueRef));
                  }

                  if (forwardNotificationValueRef.current) {
                        forwardNotificationValueRef.current.removeEventListener('animationend', () => animateNotificationOut(forwardNotificationValueRef));
                  }

                  if (playerType == "Avplayer") {
                        console.log(window.Tizen.Player)
                        window.Tizen.Player.stop();
                        window.Tizen.Player.clearListener();
                  }
                  document.body.classList.add('bg-primary');
                  window.document.removeEventListener('playerEvent', playerEvent);
                  clearInterval(interval2);

            };



      }, []);



      function handler({ keyCode }) {
handleShowDiv()
            if (ESCAPE_KEYS.includes(String(keyCode))) {
if(isOpen){
      setIsOpen(false)
      focusSelf()
      return
}
if(isBottomSheetOpen){
      setBottomSheetOpen(false)
      focusSelf()
      return

                  } if (showUI == "0") {
                        setShowUI("1")
                  } else {
                        handleStop();

                        navigate(-1) }


            } else {

                  if (ENTER_KEY.includes(String(keyCode))) {
if(showUI !='0'){
      setShowUI('0')
      setFocus("focusPlay")
      handleShowDiv()
      setPlaying(false)

}

                  }
                  if (REWIND_KEYS.includes(String(keyCode))) {
                        rewindVideo()

                  }
                  if (FF_KEYS.includes(String(keyCode))) {
                        forwardVideo()

                  }
                  if (PLAY_KEYS.includes(String(keyCode))) {
                        setPlaying(true)


                  }
                  if (PAUSE_KEYS.includes(String(keyCode))) {
                        setPlaying(false)

                  }
                  if (PAUSEPLAY_KEYS.includes(String(keyCode))) {
                        handlePausePlay()
                  }
                  if (STOP_KEYS.includes(String(keyCode))) {
                        handleStop();
                        navigate(-1);
                  }

            }
      }
      useEventListener('keydown', handler);
      const closeFn = (obj) => {
            setBottomSheetOpen(false)
            setCurrentVideoSize(obj)
            focusSelf()
            if (playerRef.current)
                  playerRef.current.getInternalPlayer().style.objectFit = obj.class


      }
      const setAvplayerSpeed=(speed)=>{
            if(playerType=='Avplayer'){
                  window.Tizen.Player.speed(speed)

            }
      }
      const closeFnSpeed = (obj) => {
            setIsOpen(false)
            focusSelf()

            switch (obj.id){
                  case '0':
                  setRate(0.5)

                        setAvplayerSpeed(-2)
                  break;
                  case '1':
                        setRate(1)
                        setAvplayerSpeed(1)

                        break;

                  case '2':
                        setRate(1.5)

                        setAvplayerSpeed(2)
                        break;

                  case '3':
                        setRate(2)

                        setAvplayerSpeed(4)
                        break;

            }

      }

      const closeFnCaption = (index) => {
            setIsOpen(false)
            focusSelf()
if(index==99){
      setTimeout(() => {

      window.Tizen.Player. toggleSubtitles(true)
      })
}else{
      setTimeout(() => {
            window.Tizen.Player.toggleSubtitles(false)

            window.Tizen.Player.setTrack('TEXT', index)
      })
}


      }
      const closeFnAudio = (index) => {
            setIsOpen(false)
            focusSelf()

            setTimeout(() => {
                  window.Tizen.Player.setTrack('AUDIO', index)
            })

      }


   const handleShowDiv = () => {
            if (timeoutIdRef.current !== null) {
                  clearTimeout(timeoutIdRef.current);
            }


            const newTimeoutId = setTimeout(() => {
                  setShowUI("11");
            }, 10000);
            timeoutIdRef!!.current = newTimeoutId;

      };



      const [selectedOption, setSelectedOption] = useState(null);

      const options = [
            {
                  label: 'Fruits',
                  branches: ['Apple', 'Banana', 'Orange'],
            },
            {
                  label: 'Vegetables',
                  branches: ['Carrot', 'Broccoli', 'Spinach'],
            },
            {
                  label: 'Colors',
                  branches: ['Red', 'Green', 'Blue'],
            },
      ];

      const handleOptionSelect = (option) => {
            setSelectedOption(option);
      };

      const toggleDropdown = () => {
            setIsOpen(!isOpen);
      };
      const handleLike = (payload) => {
            var streamId = String(movie.stream_id);
            const arrayFav = [...listVodFav]
            if (listVodFav.includes(streamId)) {
                  let arr = arrayFav.filter(el => el !== streamId)
                  deleterow(streamId, "movie", profile);

                  changeListVodFav(arr)
            } else {
                  arrayFav.push(streamId)
                  insertData(streamId, "movie", profile)
                  changeListVodFav(arrayFav)

            }
            setMovieLiked((serielike) => !serielike)
      };

      return <> <FocusContext.Provider value={focusKey}>
            <div className=" h-full w-full overflow-hidden" ref={ref}>
                  <div className="h-full w-full">
                        <div className=" w-full h-full">
                              {playerType != "Avplayer" &&  <div
                        className=" w-full h-full  bg-black"
                  >
<VideoPlayer videoSource={srcVod} handleDuration={handleDuration} handleProgress={handleProgress} currentVideoSize={currentVideoSize} playerRef={playerRef} playing={playing} rate={rate} brightness={1} changeplaying={()=>{setPlaying(false)}} handleBuffer={handleBuffer} handleBufferEnd={handleBufferEnd}
                        handleError={handleError} />
                        </div>}
                              {buffering &&
                                    <Progress />
                              }
                              {error &&
                                    <div className="flex items-center justify-center w-full absolute z-30 right-0 left-0">
                                          <div
                                                className="group animate-bounce	 relative   focusable w-[26rem] transition-all duration-300 h-[5rem] outline-none border-0 bottom-[18rem] z-10"
                                          >
                                                <div
                                                      className={(true ? "bg-thirdCOlor" : "") + " w-1/3 mx-auto h-[0.4rem] top-[-0.3rem] absolute left-1/2 -translate-x-1/2 "}
                                                />

                                                <div className="backdrop-blur-3xl h-[5rem] bg-primarytransparent relative w-full">
                                                      <div className="w-full h-full bg-center bg-cover absolute bottom-0 left-0 flex flex-col justify-center text-center">
                                                            <h2 className="text-white text-normal my-2 text-xl">Error play</h2>

                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              }
                              {subtitile != "" && <div className="absolute z-30 right-0 left-0 bottom-8 text-center mx-10 text-4xl font-bold text-white" dangerouslySetInnerHTML={{ __html: subtitile} } >
                              </div>
                              }
</div>

                        <div className="video-rewind-notify rewind notification" ref={rewindNotificationValueRef}>
                              <div className="rewind-icon icon flex flex-col">
                                    <i className="left-triangle triangle text-4xl">◀◀◀</i>
                                    <span className="rewind text-2xl mt-4">{ffrewind} seconds</span>
                              </div>
                        </div>
                        <div className="video-forward-notify forward notification" ref={forwardNotificationValueRef}>
                              <div className="forward-icon icon flex flex-col">
                                    <i className="right-triangle triangle text-4xl">▶▶▶</i>
                                    <span className="forward text-2xl mt-4">{ffrewind} seconds</span>
                              </div>
                        </div>


                  </div>
             {showUI =='0'&&    <div className="absolute left-0 right-0 top-0 bottom-0 bg-gradient-to-b from-black/0 from-10% via-black/60 via-70% to-black/90 to-90% flex items-end pb-3">
                        <div
                              className="absolute  navbar  w-full top-0 left-0 z-10 pt-8   flex
                        justify-center items-center transition-all"
                        > <div className='relative container mx-auto flex flex-row justify-end '>
                                    {hasAudio && <img className="w-14 h-14 mx-auto mr-4 animated-shild " src="/images/audio.svg" alt="" />}
                                    {hasText && <img className="w-14 h-14 mx-auto mr-4 animated-shild " src="/images/cc.svg" alt="" />}                                    < div className="flex  items-center mr-6 text-right">
                                          <div className="text-white">
                                                <div className="text-2xl">{time.time}</div>
                                                <div className="text-lg">{time.date}</div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                      <div
                        className="relative  mx-5 navbar  w-full top-0 left-0 z-10 pt-8   flex
  justify-center items-center transition-all"
                  >
                        <div className='flex justify-center p-10 w-[28rem]'>
                              <img src={movie.stream_icon} className='rounded-md w-20 h-24' />
                              <div className="text-white text-left ml-4 flex items-center	">
                                    <h3 className="text-2xl text-grey-darkest font-medium">
                                          {movie.name}                                    </h3>
                                    <p className="text-sm text-grey mt-1"></p>
                              </div>
                        </div>
                        <div className="flex-grow">
                              <div className="flex justify-between items-center w-3/6 mx-auto">
                                          <CircleButtonPlayer event="rewind" eventFn={rewindVideo}>


                                                <CircleButtonPlayer.Slot name="rightActions">
                                                      <RWIcon classes="fill-textcolor2" />
                                                </CircleButtonPlayer.Slot>
                                                <CircleButtonPlayer.Slot name="rightActionsFocused">
                                                      <RWIcon classes="fill-primary" />
                                                </CircleButtonPlayer.Slot>

                                          </CircleButtonPlayer>
                                          <CircleButtonPlay event="play" playing={!playing} />
                                          <CircleButtonPlayer event="forward" eventFn={forwardVideo}>


                                                <CircleButtonPlayer.Slot name="rightActions">
                                                      <FFICON classes="fill-textcolor2" />
                                                </CircleButtonPlayer.Slot>
                                                <CircleButtonPlayer.Slot name="rightActionsFocused">
                                                      <FFICON classes="fill-primary" />
                                                </CircleButtonPlayer.Slot>
                                          </CircleButtonPlayer>

                              </div>

                              <div className="flex text-white  mx-10 mt-4 justify-center items-center">
                                    <span className="mr-2 text-sm text-white/60">{formatTime(currentTime)}</span>

                                    <div className="bg-white/50 flex-grow h-[0.4rem] rounded-full relative " >
                                          <div className="bg-white flex-grow h-[0.4rem] rounded-full absolute left-0 top-0" style={{ width: `${(currentTime / duration) * 100}%` }} />
                                    </div>


                                    <span className="ml-2 text-sm text-white/60">{formatTime(duration)}</span>
                              </div>
                        </div>
                        <div
                              className="menuItems flex justify-center items-center rounded-full mr-4 w-[28rem]"
                              id="menu"
                        >


                                    <CircleButtonLike event="like" isliked={movieLiked}>

                                          <CircleButtonLike.Slot name="leftActions">
                                                <Like class="fill-rose-600" />
                                          </CircleButtonLike.Slot>
                                          <CircleButtonLike.Slot name="leftActionsFocused">
                                                <Like class="fill-rose-300" />
                                          </CircleButtonLike.Slot>
                                          <CircleButtonLike.Slot name="leftActionsliked">
                                                <LikeEmpty class="fill-rose-600" />
                                          </CircleButtonLike.Slot>
                                          <CircleButtonLike.Slot name="leftActionsFocusedliked">
                                                <LikeEmpty class="fill-rose-300" />
                                          </CircleButtonLike.Slot>

                                    </CircleButtonLike>

                              <CircleButton event="resize">


                                    <CircleButton.Slot name="rightActions">
                                          <ResizeIcon />
                                    </CircleButton.Slot>
                              </CircleButton>
                              <CircleButton event="setting" >


                                    <CircleButton.Slot name="rightActions">
                                          <Setting />
                                    </CircleButton.Slot>
                              </CircleButton>
                        </div>
                  </div>




                  </div>
                  }
            </div>

      </FocusContext.Provider>
            <BottomSheet isOpen={isBottomSheetOpen} onClose={() => {
                  focusSelf(); setBottomSheetOpen(false) }}>
                  {isBottomSheetOpen && <Size isOpen={isBottomSheetOpen} onCloseFn={closeFn} size={currentVideoSize} />
                  }
            </BottomSheet>
            <BottomSheet isOpen={isOpen} onClose={() => { focusSelf(); setIsOpen(false) }} size={(hasAudio) ?"70%":"50%"} >
                  {isOpen && <SettingPlayer isOpen={isOpen} onCloseFnCaption={closeFnCaption} onCloseFnSpeed={closeFnSpeed}
                        trucks={trucks}
                        rate={rate}
                        onCloseFnAudio={closeFnAudio}
                   />
                  }
            </BottomSheet>

            {errorInternet && <NoInternet />
            }


      </>

}

export default MoviePlayer;