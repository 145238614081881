import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { motion } from 'framer-motion';

const ItemSettingSheet = ({index,obj,onClose }) => {

      const { ref, focused, focusKey,focusSelf } = useFocusable({
            focusKey: "S" + obj.id,
            onEnterPress: () => {
                  if (typeof onClose === 'function') {
                        onClose(obj.id);
                  }else{
                        console.log(typeof onClose)
                  }
            },
      });

      function onEnterPress(): void {
            if (typeof onClose === 'function') {
                  onClose(obj.id);
            } else {
             }

      }

      return (
            <FocusContext.Provider value={focusKey}>
                  <div ref={ref} id={"S" + obj.id}
                       className="group relative focusable w-full transition-all duration-300 h-28 outline-none border-0"
                  >


                        <div onClick={onEnterPress}
                             onMouseEnter={focusSelf}
                             className={(focused ? " text-black" : "bg-categoryColor text-white ") + "backdrop-blur-3xl h-24  relative w-full  "}>
                              <motion.div
                                  initial={{opacity: 0}}
                                  animate={{opacity: focused ? 1 : 0}}
                                  transition={{duration: 0.3}}
                                  className={"absolute h-full  w-full flex justify-center items-end"}>

                                    <div
                                        className={(focused ? "bg-white " : "") + "w-1/3 absolute -bottom-[4px] h-[5px]  mx-auto  left-1/2 transform -translate-x-1/2 "}/>
                                    <div className="w-full h-full overflow-hidden flex justify-center items-end">
                                          <div className="bg-white w-1/3   transform h-8 filter  top-0 overflow-hidden
                              blur-[2rem]
"></div>
                                    </div>
                              </motion.div>
                              <div
                                  className="w-full h-full bg-center bg-cover absolute bottom-0 left-0 flex flex-col justify-center text-center">
                                    <h2 className="text-white text-normal my-2 text-xl">{obj.title}</h2>
                                    <div
                                        className={(focused ? "block" : "hidden") + " absolute left-0 top-0 right-0 w-full h-full  "}
                                        style={{backgroundImage: `url('../images/bordergradient.svg')`}}
                                    />
                              </div>
                        </div>
                  </div>
            </FocusContext.Provider>
      );
};

export default ItemSettingSheet;
